@import url("https://fonts.bunny.net/css?family=Lato:400,700");

@import url('https://fonts.bunny.net/css?family=Source+Sans+Pro:400,400i,600,600i,700,700i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');

/* General */

body {
  background: #fbfcfe;
  min-height: 100%;
  overflow-x: hidden;
  font-family: 'Source Sans Pro', 'Lato', sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "pnum";
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;
}

header > .container-fluid {
  margin: 0;
  padding: 0;
}

.large-heading-text {
  font-weight: 600;
  margin-bottom: 1em;
}

.hvr-card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow-x: hidden;
  transition: box-shadow 0.5s ease;
}

.hvr-card:hover {
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.10)
}

.card-block {
  padding: 1em 1em 0em 1em;
}

.card-title {
  font-size: 20px;
  color: #FF519E; /* CHANGE TO DYNAMIC */
}

.card-link:hover {
  text-decoration: none;
}

.card-link:focus {
  text-decoration: none;
}

.card-description {
  color: #676767;
  line-height: 20px;
  font-size: 14px;
}

.small-capital-text {
  font-size: 12px;
  font-weight: 600;
  color: #797979;
  text-transform: uppercase;
}

.small-capital-text a {
  color: #FF519E; /* CHANGE TO DYNAMIC */
  text-decoration: none;
}

.author-text span:first-of-type {
  padding-left: 0.5em;
}

.author-text {
 border-top: 1px solid rgba(0,0,0,0.06);
  margin: 0 -1em 1em -1em;
  padding: 1em 0.5em 0.25em 0.5em;
}

.author-image {
  max-height: 30px;
  display: inline-block;
  border-radius: 4px;
  border: 2px solid white;
}

.author-image:not(:first-of-type) {
  margin-left: -10px;
}


/* Header */

@media (min-device-width: 320px) {
  .navbar {
    margin-left:-15px;
    margin-right:-15px;
  }
}
@media (min-width: 481px) {
  .navbar {
    margin-left:-30px;
    margin-right:-30px;
  }
}

.bg-faded {
  height: 170px;
  background-color: #FF519E; /* CHANGE TO DYNAMIC */
  border-bottom-left-radius: 200% 50%;
  border-bottom-right-radius: 200% 50%;
  padding: 30px 45px;
}

.logo {
  max-height: 40px;
  max-width: 250px;
  margin-right: 5px;
}

.navbar-brand > span {
  color: white; /* CHANGE TO DYNAMIC */
  font-weight: 600;
}

.nav-items {
  float: right;
}

.statuspage
  .color-dot
    border-radius 50%
    display inline-block
    width 10px !important
    height 10px !important
    margin-right 5px
    &.critical
      background-color primary-red
    &.major
      background-color #e67e22
    &.minor
      background-color #f1c40f
    &.none
    &.up
      background-color primary-green

@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
  li.custom-link, li.statuspage {
  	display: none;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(255,255,255,.7); /* CHANGE TO DYNAMIC */
  transition: color 0.25s ease;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(255,255,255); /* CHANGE TO DYNAMIC */
}

.btn.btn-primary.btn-contact {
  background-color: white;
  border: none;
  color: #FF519E; /* CHANGE TO DYNAMIC */
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
  top: 0px;
  transition: box-shadow 0.5s ease;
}

.btn.btn-primary.btn-contact:hover {
  box-shadow: 0 7px 20px 0 rgba(0,0,0,0.10);
}

#lang-dropdown {
  text-transform: uppercase;
  background-color: white;
  color: #ff519e; /* CHANGE TO DYNAMIC */
  padding: 0.3em 1em 0.3em 1em;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
  transition: box-shadow 0.5s ease;
}

#lang-dropdown:hover {
  box-shadow: 0 7px 20px 0 rgba(0,0,0,0.10);
}

.lang-dropdown-icon {
  width: 20px;
  float: left;
  margin: 2px 8px 0 0;
}

.dropdown-menu {
  box-shadow: 0 7px 20px 0 rgba(0,0,0,0.10);
  border: none;
}

/* Search */

#instant-search input {
  margin-top: -2em;
  border-radius: 4px;
  padding: 1em;
  background-color: rgb(255, 255, 255);
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
  -webkit-appearance: none;
  width: 100%;
  display: block;
  transition-property: box-shadow;
  transition-duration: .5s;
  transition-timing-function: ease;
}

#instant-search input:focus {
  box-shadow: 0 2px 30px 0 rgba(0,0,0,0.20);
  -webkit-appearance: none;
  outline: none;
  caret-color: #FF519E; /* CHANGE TO DYNAMIC */
}

#searchresults {
  margin-top: -0.2em;
}

#search-container .fa {
  float: right;
  margin-right: 1em;
  margin-top: -2em;
  font-size: 1.25em;
  position: relative;
  z-index: 2;
  color: grey;
}

#hits {
  background-color: white;
  padding: 0 1em;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.16);
  border-radius: 0 0 4px 4px;
}

.search-article-title {
  font-size: 20px;
  color: #FF519E; /* CHANGE TO DYNAMIC */
}

.search-article-link:hover {
  text-decoration: none;
}

.search-article-link:focus {
  text-decoration: none;
}

.search-article-description {
  color: #676767;
  line-height: 20px;
  font-size: 14px;
}

.search-article-row:last-of-type hr {
  display: none;
}

/* Content */

#content {
  max-width: 1000px;
  display: block;
  margin: 0 auto;
  padding: 4em 0 0 0;
}

/* Categories */
#categories {
  margin-top: 2em;
}

#breadcrumbs {
  font-size: 14px;
}

.category-icon {
  max-height: 70px;
  color: #FF519E; /* CHANGE TO DYNAMIC */
  padding-bottom: 1.25em;
}

/* Category page */

#subcategories {
  margin: 0em -12em 2em -12em;
  background-color: rgba(0,0,0,0.02);
  border-radius: 4px;
  border-top: 1px solid rgba(0,0,0,0.06);
  border-bottom: 1px solid rgba(0,0,0,0.06);
  padding: 1em 11em;
}

#subcategories .card {
  margin-bottom: 1em;
}

/* Article */

.full-article-card {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 1.5em 2em;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
}

.full-article-author-meta {
  background-color: rgba(0,0,0,0.02);
  padding: 1em 4em;
  margin: 2em -4em;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.full-article-author-image {
  border-radius: 4px;
  max-height: 40px;
  display: inline-block;
}

#article .card-block p:not(.meta),#article .card-block #htmlWithToc p:not(.meta) {
  font-size: 17px;
}

#article h3,h4,h5 {
  margin: 1.2em 0 0.5em 0;
}

#article table {
  margin: 2em -3em;
  display: block;
  overflow-x: auto;
}

#article thead {
  background-color: rgba(0,0,0,0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

#article th {
  padding: 1em;
  text-align: left;
  font-weight: 600;
}

#article tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.02);
}

#article td {
  padding: 1em;
  border-right: 1px solid rgba(0,0,0,0.06);
  font-size: 14px;
  min-width: 15em;
}

#article table > tbody > tr > td > pre {
  border-top: none;
  border-bottom: none;
  border-radius: 4px;
  margin: 0;
  padding: 1em;
}

#article ol {
  counter-reset: li;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 1rem;
}

#article ol > li {
  position: relative;
  margin: 0 0 6px 2em;
  padding: 0.3em 8px;
  list-style: none;
}

#article ol > li:before {
  color: #e80074; /* CHANGE TO DYNAMIC */
  border-color: #ec0e79; /* CHANGE TO DYNAMIC */
  background-color: #ffeaf4; /* CHANGE TO DYNAMIC */
  content: counter(li);
  counter-increment: li;
  position: absolute;
  top: 0.1em;
  left: -2em;
  box-sizing: border-box;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.2em;
  margin-top: 0.4em;
  border-style: none;
  border-radius: 50%;
  line-height: 1.4em;
  text-align: center;
}

#article ol ol > li:before {
  content: counter(li, lower-latin);
}

#article ol ol ol > li:before {
  content: counter(li, lower-roman);
}

#article ul {
  list-style: none;
  padding: 0;
  font-size: 17px;
  margin: 1em 0;
}

#article ul > li {
  padding-left: 1em;
  text-indent: -.7em;
}

#article ul > li::before {
  content: "•";
  padding-right: 0.25em;
  position: relative;
  top: 0.10em;
  font-size: 1.5em;
  color: #FF519E; /* CHANGE TO DYNAMIC */
}

#article ul ul {
  margin: 0;
}

#article img {
  max-width: 100%;
}

#article pre {
  background-color: rgba(0,0,0,0.03);
  margin: 2em -3.4em;
  padding: 2em 2em;
  border-top: 1px solid rgba(0,0,0,0.06);
  border-bottom: 1px solid rgba(0,0,0,0.06);
  display: block;
  overflow-x: auto;
}

#article pre:before {
  content: attr(data-content);
  display: table;
  clear: both;
  text-transform: uppercase;
  font-size: 10px;
  padding-bottom: 1em;
  font-weight: 700;
  color: rgba(0,0,0,0.20);
}

#article .tip-callout {
  background-color: #ddf5ff;
  margin: 2em -3em;
  padding: 1em 1em;
  border-top: 1px solid rgba(0,0,0,0.06);
  border-bottom: 1px solid rgba(0,0,0,0.06);
  padding-left: 3em;
  text-indent: -3em;
}

#article .tip-callout:before {
  content: '\f075';
  margin-right: .75em;
  font-family: FontAwesome;
  color: white;
  padding: 0.5em;
  background-color: #36c6fe;
  border-radius: 4px;
  display: initial;
  margin-left: 0.3em;
}

#article .tip-callout a {
  color: #0a769e;
  font-weight: bold;
  text-decoration: none;
}

#article .tip-callout a:hover {
  cursor: pointer;
}

#article .note-callout {
  background-color: #fff5dd;
  margin: 2em -3em;
  padding: 1em 1em;
  border-top: 1px solid rgba(0,0,0,0.06);
  border-bottom: 1px solid rgba(0,0,0,0.06);
  padding-left: 3em;
  text-indent: -3em;
}

#article .note-callout:before {
  content: '\f05a';
  margin-right: .75em;
  font-family: FontAwesome;
  color: white;
  padding: 0.5em;
  background-color: #ffbc00;
  border-radius: 4px;
  display: initial;
  margin-left: 0.3em;
}

#article .note-callout a {
  color: #c1961d;
  font-weight: bold;
  text-decoration: none;
}

#article .note-callout a:hover {
  cursor: pointer;
}

#article .warning-callout {
  background-color: #ffd8d8;
  margin: 2em -3em;
  padding: 1em 1em;
  border-top: 1px solid rgba(0,0,0,0.06);
  border-bottom: 1px solid rgba(0,0,0,0.06);
  padding-left: 3em;
  text-indent: -3em;
}

#article .warning-callout:before {
  content: '\f071';
  margin-right: .75em;
  font-family: FontAwesome;
  color: white;
  padding: 0.5em;
  background-color: #ff0000;
  border-radius: 4px;
  display: initial;
  margin-left: 0.3em;
}

#article .warning-callout a {
  color: #b51212;
  font-weight: bold;
  text-decoration: none;
}

#article .warning-callout a:hover {
  cursor: pointer;
}

#article .inline-code {
  color: #383a3c;
}

/* Stop callouts from going out of bounds in table cells */
#article table .note-callout,#article table .warning-callout,#article table .tip-callout {
  margin: 2em -1em;
}

#article blockquote {
  background-color: rgba(0,0,0,0.03);
  margin: 2em -3em;
  padding: 2em 3em;
  border-top: 1px solid rgba(0,0,0,0.06);
  border-bottom: 1px solid rgba(0,0,0,0.06);
  display: block;
  overflow-x: auto;
  font-style: italic;
}

#article a {
  color: #ff519e;
  font-weight: 600;
}

#article span a {
  color: inherit;
}

#article a.articleButton button {
  color: white;
  background-color: #FF519E;
  border: none;
  border-radius: 4px;
  padding: .5em 1em;
  margin: .5em 1em .5em 0;
  cursor: pointer;
}
#article a.articleButton button:hover {
  background-color: #ff1e81;
}

#article #feedback {
  background-color: rgba(0,0,0,0.02);
  border: 1px solid rgba(0,0,0,0.06);
  border-radius: 4px;
  padding: 0em 1em 1em 1em;
  width: 100%;
}

#article #feedback h3 {
  text-align: center;
  font-weight: 500;
}

#article #feedback .btn-group {
  width: 100%;
}

#article #feedback .btn-group .btn {
  width: 33%;
  opacity: 0.6;
  transition: opacity 0.5s ease;
}

#article #feedback .btn-group .btn:hover {
  opacity: 1;
}

#article #feedback .thanks {
  text-align: center;
}

#article #feedback i {
  display: block;
}

#article #related {
  text-align: center;
  background-color: rgba(0,0,0,0.02);
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.06);
  padding: 0em 1em 1em 1em;
}

#article #related > ul {
  list-style: none;
}

#article #related > ul > li {
  padding-left: 0;
}

#article #related ul > li::before {
  display: none;
}

@media (min-width: 70em) #article #toc {
  position: fixed !important;
  width: 300px;
  top: 200px;
  left: 30px;
  bottom: 0px;
  max-height: 500px;
  overflow-y: scroll;
}

@media (min-width: 95em) {
  #article #toc {
    position: fixed !important;
    width: 300px !important;
    top: 200px !important;
    left: 30px !important;
    bottom: 0px !important;
    max-height: 500px !important;
    overflow-y: scroll !important;
  }
}

#article #toc {
  padding: 20px;
  margin-bottom: 1em;
  background-color: rgba(0,0,0,0.03);
  border: 1px solid rgba(0,0,0,0.06);
  border-radius: 4px;
}

#article #toc .card.responsive-toc {
  padding: 20px;
  margin-bottom: 1em;
  background-color: rgba(0,0,0,0.03);
  border: 1px solid rgba(0,0,0,0.06);
  border-radius: 4px;
}

#article #toc::before {
  font-size: 20px;
  font-weight: 600;
}

#article #toc ul {
  margin: 0;
}

#article #toc > ul li {
  margin-top: 0;
  list-style: none;
  color: #FF519E; /* CHANGE TO DYNAMIC */
}

#article details {
  background: #b7cbeb;
  padding: 1em 1.5em;
  line-height: 1.5em;
  border-radius: 3px;
  margin-top: 1em;
}

#article details + details {
  border-top: 3px solid white;
  border-radius: 0 0 3px 3px;
  margin-top: -3px;
}

#article details + *:not(details) {
  margin-top: 1em;
}

#article details div {
  padding-top: .5em;
}

#article details div p:last-child {
  margin-bottom: 0.5em;
}

#article details summary {
  color: #0047a1;
  margin-left: -.5em;
  padding-left: .5em;
  font-weight: bold;
  cursor: pointer;
  display: list-item;
}

/* Contact Form */
#contact-modal .modal-content {
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

#contact-modal .btn-primary {
  background-color: #FF519E; /* CHANGE TO DYNAMIC */
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

#contact-modal ul.ticket-deflection-results {
  list-style: none;
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 0.8em;
}
